<template>
  <div>
    <b-modal
      id="edit-saturday-working-days"
      ref="modal"
      header-class="headerModal"
      centered
      hide-footer
      size="xl"
      :title="$t('EditSaturdayWork')"
      @close="onCancel"
    >
      <!-- <p>Your are activating <b>{{ editId.firstName }}</b></p> -->
      <table style="width: 100%;">
        <thead>
          <tr>
            <th style="padding: 5px;">
              {{ $t('EmployeeName') }}
            </th>
            <th
              style="text-align: left;display: flex;
              justify-content: space-around;"
            >
              <span>{{ $t('CalculationDate') }}</span>
              <span>{{ $t('WorkPlace') }}</span>
              <span>{{ $t('Spendings') }}</span>
            </th>
            <!-- <th style="text-align: left;">
              Spendings
            </th> -->
          </tr>
        </thead>
        <tbody style="border: 1px solid #82868c;">
          <!-- v-for="item1 in getCustom_TruckStuffId"
        :key="item1.id" -->
          <tr>
            <td
              style="border: 1px solid #82868c"
            >{{ getCustom_TruckStuffId.employeeName }}</td>
            <td
              v-for="(item2, index) in getCustom_TruckStuffId.workingDates"
              :key="index"
              style="text-align: left; display: flex; flex-direction: column;"
            >
              <div
                style="display: flex;
                justify-content: space-around; margin-left: 30px; "
              >
                <p>{{ formatDate(item2.dateWorked) }}</p>
                <input
                  v-model="item2.worked"
                  :disabled="!item2.isEditable || !canToggleWorked(item2)"
                  type="checkbox"
                  @change="handleCheckboxChange(item2)"
                >
                <span
                  style="width: 30%;"
                >
                  <input
                    v-model="item2.workPlace"
                    style="width: 100%; text-align: left; border: 1px solid #82868c; margin: 5px;"
                    :disabled="!item2.isEditable"
                    type="text"
                    @input="handleInputChange(item2)"
                  >
                </span>
                <span style="width: 30%;">
                  <input
                    v-model="item2.spendings"
                    style="width: 100%; text-align: left; border: 1px solid #82868c; margin: 5px;"
                    type="text"
                  >
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <b-button
        type="button"
        variant="none"
        style="margin-left: 10px; margin-top: 10px;"
        class="buttonSubmit"
        @click="onSubmit(getCustom_TruckStuffId)"
      >
        {{ $t("Submit") }}
      </b-button>
      <b-button
        type="reset"
        variant="danger"
        style="margin-top: 10px;"
        class="buttonCancel"
        @click="onCancel"
      >
        {{ $t("Cancel") }}
      </b-button>

    </b-modal></div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    editId: {
      type: Object,
    },
    editToggle: {
      type: Boolean,
    },
    // eslint-disable-next-line vue/require-default-prop
    emplyeeId: {
      type: Object,
    },
  },
  data() {
    return {
      form: [],
      totalItems: '',
      options: [],
      selected: null,
      button: false,
      test: this.editToggle,
    }
  },
  computed: {
    ...mapGetters(['getCustom_TruckStuffId']),
  },
  watch: {
    // emplyeeId(value) {
    //   this.get_custom_TruckStuff({
    //     date: value.date,
    //     employeeUserId: value.employeeId,
    //   })
    // },
    'item2.workPlace': 'handleInputChange',

  },
  mounted() {
    // this.get_custom_TruckStuff({
    //   date: this.emplyeeId.date,
    //   employeeUserId: this.emplyeeId.employeeId,
    // })
  },
  methods: {
    ...mapActions(['get_custom_TruckStuff', 'resetCustomTruckStffid']),
    // handleInputChange(employeeUserId, workPlace, dateWorked, spendings, truckStuffId) {
    //   console.log(employeeUserId)
    //   console.log(workPlace)
    //   console.log(dateWorked)
    //   console.log(spendings)
    //   console.log(truckStuffId)

    // },
    handleCheckboxChange(item) {
      if (!item.worked) {
        item.workPlace = '';
        item.spendings = '';
      }
    },
    handleInputChange(item) {
      if (item.workPlace.trim() !== '' || item.spendings.trim() !== '') {
        item.worked = true;
      } else {
        item.worked = false;
      }
    },
    canToggleWorked(item) {
      return item.workPlace.trim() !== '' || item.spendings.trim() !== '';
    },
    onSubmit(value) {
      // console.log('value', value)
      this.$emit('editSaturday', value)
      this.resetCustomTruckStffid()
      this.$refs.modal.hide()
      this.resetForm()
    },
    resetForm() {
      this.getCustom_TruckStuffId = {}
      this.resetCustomTruckStffid()
    },
    onCancel() {
      this.resetForm()
      this.$refs.modal.hide()
      // this.isActive = true
      // this.editToggle = !this.editToggle
    },
    formatDate(date) {
      return moment(date).format('DD-MM-YYYY')
    },
  },
}
</script>

<style lang="scss" scoped>
  form {
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  .deactivate-modal__select {
    display: flex;
    flex-direction: column;
    select {
      margin-bottom: 10px;
      padding: 10px;
    }
    button {
      align-self: end;
    }
  }
  .flex-form {
    display: flex;
    flex-direction: column;
  }
  .my-class {
  background: black;
  font-weight: bold;
}

thead tr th{
  color: black;
}

  // .my-second-class > .modal-dialog > .modal-content > .modal-header {
  // background: black;
  // color: white;
  // }
</style>
