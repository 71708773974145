<template>
  <div>
    <b-modal
      id="add-saturday-working-days"
      ref="modal"
      header-class="headerModal"
      centered
      hide-footer
      size="xl"
      :title="$t('AddSaturdayWork')"
      @click="removeLists"
      @close="onCancel"
    >
      <!-- <p>Your are activating <b>{{ editId.firstName }}</b></p> -->
      <table style="width: 100%;">
        <thead>
          <tr>
            <th>
              {{ $t('EmployeeName') }}
            </th>
            <th
              style="text-align: left; display: flex;
              justify-content: space-around; ;"
            >
              <span>{{ $t('CalculationDate') }}</span>
              <span>{{ $t('WorkPlace') }}</span>
              <span>{{ $t('Spendings') }}</span>
            </th>
          </tr>
        </thead>
        <tbody
          style="border: 1px solid black"
        >
          <tr>
            <td
              style="border: 1px solid black"
            ><div class="black">
               <b-icon-search
                 v-if="searchUser == ''"
                 class="search1"
                 style="position: absolute; left: 5px; top: 12px"
               />
               <b-icon-x-circle
                 v-else-if="searchUser != '' && !noSearch"
                 class="search1"
                 style="position: absolute; right: 20px; top: 12px; cursor: pointer"
                 @click="searchUser = '', onCancel1()"
               />
               <input
                 v-model="searchUser"
                 autocomplete="off"
                 type="text"
                 name="search-box"
                 :placeholder="$t('FullName')"
                 :style="
                   getFilteredActiveUsers.length > 0 && noSearch
                     ? 'border-radius: 8px 8px 0px 0px'
                     : ''
                 "
                 @keyup="inputChanged"
                 @keydown.down="onArrowDown"
                 @keydown.up="onArrowUp"
               >
             </div>
              <div
                v-if="
                  getFilteredActiveUsers.length > 0 &&
                    noSearch
                "
                ref="scrollContainer"
                class="dropdrop"
              >
                <div
                  v-for="(result, index) in getFilteredActiveUsers"
                  :key="index"
                  ref="options"
                  style="cursor: pointer"
                  class="search-item"
                  :class="{ 'is-active': index === arrowCounter }"
                  @click="
                    searchByOn(result);
                    noSearch = false;
                  "
                >
                  <p style="margin: 0">
                    {{ result.fullName }}
                  </p>
                </div>
              </div>
              <!-- v-for="item in getActiveRouteById"
              :key="item.tourId" -->

            </td>
            <td

              style="text-align: left;  display: flex; flex-direction: column; padding: 3px;"
            >
              <div
                v-for="(item, index) in getCustom_TruckStuffId.workingDates"
                :key="item.id"
                style="display: flex; justify-content: space-around; margin-left: 30px;padding: 5px;"
              >
                <p>{{ formatDate(item.dateWorked) }}</p>
                <input
                  v-model="item.worked"
                  type="checkbox"
                  :disabled="!item.isEditable || !canToggleWorked(item)"
                  @change="handleCheckboxChange(item, index)"
                >
                <!-- <p> @change="saveDate(item, index)"</p> -->
                <span style="width: 30%;">
                  <input
                    v-model="item.workPlace"
                    :disabled="!item.isEditable"
                    style="width: 100%; text-align: left;"
                    type="text"
                    @input="handleInputChange(item)"
                  >
                </span>
                <span style="width: 30%;">
                  <input
                    v-model="item.spendings"
                    :disabled="!item.isEditable"
                    style="width: 100%; text-align: left;"
                    type="text"
                    @input="handleInputChange(item)"
                  >
                </span>
              </div>


            </td>
          </tr>
        </tbody>
      </table>
      <div
        style="margin-top: 10px;"
      >
        <b-button
          type="button"
          variant="none"
          style="margin-left: 10px;"
          class="buttonSubmit"
          @click="onSubmit()"
        >
          {{ $t("Submit") }}
        </b-button>

        <b-button
          type="reset"
          variant="danger"
          class="buttonCancel"
          @click="onCancel"
        >
          {{ $t("Cancel") }}
        </b-button>

      </div>
    </b-modal></div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    editId: {
      type: Object,
    },
    editToggle: {
      type: Boolean,
    },
    // eslint-disable-next-line vue/require-default-prop
    emplyeeId: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    date: {
      type: String,
    },
    // eslint-disable-next-line vue/require-default-prop
    time: {
      type: String,
    },
  },
  data() {
    return {
      // filters: {
      //   page: 1,
      //   pageSize: 20,
      //   query: '',
      //   ...this.$route.query,
      // },
      form: [],
      totalItems: '',
      // options: [],
      selected: null,
      button: false,
      arrowCounter: 0,
      searchUser: '',
      searchUser2: null,
      test: this.editToggle,
      noSearch: false,
      replace: false,
      selectedMonth: '',
      enterPressed: false,
      months: [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December',
      ],
    }
  },
  computed: {
    ...mapGetters(['getCustom_TruckStuffId', 'getFilteredActiveUsers', 'getCustom_TruckStuffId']),
    saturdays() {
      const selectedDate = moment(this.date, 'DD-MM-YYYY');
      const currentDate = moment(selectedDate).startOf('month');
      const saturdays = [];
      // console.log(saturdays)

      while (saturdays.length < 4) {
      // Check if the current day is Saturday
        if (currentDate.day() === 6) {
          saturdays.push(currentDate.format('DD-MM-YYYY'));
        }
        // Move to the next day
        currentDate.add(1, 'day');
      }

      return saturdays;
    },
  },
  watch: {
    // emplyeeId(value) {
    //   this.get_custom_TruckStuff({
    //     date: value.date,
    //     employeeUserId: value.employeeId,
    //   })
    // },
    searchUser(value) {
      this.selectedId = null;
      if (value == '') {
        this.searchUser2 = null;
        this.getCustom_TruckStuffId = {}
        this.removeLists();
        this.getUsersPagination({
          status: true,
          pageNumber: this.page,
          pageSize: this.pageSize,
          fullName: value,
        });
      } else if (this.enterPressed) {
        // eslint-disable-next-line no-useless-return
        return;
      } else {
        this.filterActiveUsersByName(value);
        this.noSearch = true;
      }

      const time = moment(this.time, 'DD-MM-YYYY').format('YYYY-MM-DD')
      this.get_custom_TruckStuff({
        date: time,
        employeeUserId: this.searchUser2.userID,
      })
    },
    // searchUser(value) {
    //   console.log(value)
    // },

    // searchUser(value) {
    //   this.filterActiveUsersByName(value)
    // },
    'item2.workPlace': 'handleInputChange',

  },
  mounted() {
    // this.get_custom_TruckStuff({
    //   date: this.emplyeeId.date,
    //   employeeUserId: this.emplyeeId.employeeId,
    // })
    this.initializeForm();
    // this.form = this.getCustom_TruckStuffId
    // this.get_custom_TruckStuff({
    //   date: this.date,
    //   employeeUserId: this.searchUser,
    // })
  },
  methods: {
    ...mapActions(['resetCustomTruckStffid', 'get_custom_TruckStuff', 'filterActiveUsersByName', 'getUsersPagination', 'get_custom_TruckStuff']),
    // handleInputChange(employeeUserId, workPlace, dateWorked, spendings, truckStuffId) {
    //   console.log(employeeUserId)
    //   console.log(workPlace)
    //   console.log(dateWorked)
    //   console.log(spendings)
    //   console.log(truckStuffId)

    // },
    // saveDate(item, index) {
    //   if (this.form[index].dateWorked) {
    //     this.form[index].dateWorked = item; // Save the date when the checkbox is checked
    //   } else {
    //     // eslint-disable-next-line no-unused-expressions
    //     this.form[index].dateWorked == ''; // Remove the date when the checkbox is unchecked
    //   }
    // },
    handleCheckboxChange(item) {
      if (!item.worked) {
        item.workPlace = '';
        item.spendings = '';
      }
    },
    handleInputChange(item) {
      if (item.workPlace.trim() !== '' || item.spendings.trim() !== '') {
        item.worked = true;
      } else {
        item.worked = false;
      }
    },
    canToggleWorked(item) {
      return item.workPlace.trim() !== '' || item.spendings.trim() !== '';
    },
    initializeForm() {
      this.form = this.saturdays.map(() => {
        return {
          dateWorked: '',
          workPlace: '',
          spendings: '',
        }
      });
    },
    onArrowDown(ev) {
      ev.preventDefault();
      if (this.arrowCounter < this.getFilteredActiveUsers.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },
    onArrowUp(ev) {
      ev.preventDefault();
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling();
      }
    },
    saveUser(value) {
      this.userObj = value
    },
    inputChanged(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') {
        return;
      }
      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true;
        this.searchByOn(this.getFilteredActiveUsers[this.arrowCounter]);
        this.removeLists();
        this.arrowCounter = 0;
        setTimeout(() => {
          this.enterPressed = false;
        }, 1000);
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    replaceShow(itemId) {
      this.replace = !this.replace;
      this.selectedItemId = itemId;
    },
    fixScrolling() {
      const liH = this.$refs.options[this.arrowCounter].clientHeight;
      console.log(liH);
      this.$refs.scrollContainer.scrollTop = liH * this.arrowCounter;
    },
    onSubmit() {
      // console.log('value', this.form)

      this.$emit('submit', this.getCustom_TruckStuffId)
      this.$refs.modal.hide()
      this.resetCustomTruckStffid()
      this.resetForm()
    },
    resetForm() {
      this.getCustom_TruckStuffId = {}
      this.searchUser = ''
      this.searchUser2 = ''
      this.resetCustomTruckStffid()
    },
    onCancel() {
      this.$refs.modal.hide()
      this.resetCustomTruckStffid()
      this.resetForm()

      // this.isActive = true
      // this.editToggle = !this.editToggle
    },
    onCancel1() {
      this.resetCustomTruckStffid()
      this.resetForm()

      // this.isActive = true
      // this.editToggle = !this.editToggle
    },
    formatDate(date) {
      return moment(date).format('DD-MM-YYYY')
    },
    removeLists() {
      this.noSearch = false;
      // this.resetFilteredPlateExchangeTruck();
    },

    async searchByOn(value) {
      this.searchUser = value.fullName;
      this.searchUser2 = value;
      // this.noSearch = false;
      const time = moment(this.time, 'DD-MM-YYYY').format('YYYY-MM-DD')
      await this.get_custom_TruckStuff({
        date: time,
        employeeUserId: this.searchUser2.userID,
      })
      this.noSearch = false;
      // if (this.isactive) {
      //   await this.getUsersPagination({
      //     status: true,
      //     pageNumber: this.page,
      //     pageSize: this.pageSize,
      //     firstName: value.firstName,
      //     lastName: value.lastName,
      //   });
      // }
    },
  },
}
</script>

<style lang="scss" scoped>
  form {
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  .deactivate-modal__select {
    display: flex;
    flex-direction: column;
    select {
      margin-bottom: 10px;
      padding: 10px;
    }
    button {
      align-self: end;
    }
  }
  .flex-form {
    display: flex;
    flex-direction: column;
  }
  .my-class {
  background: black;
  font-weight: bold;
}

  // .my-second-class > .modal-dialog > .modal-content > .modal-header {
  // background: black;
  // color: white;
  // }
  .button {
  width: 195px;
}
.addEmployeeButton {
  width: 210px;
}

@media screen and (max-width: 1600px) {
  .scroll {
    overflow-x: scroll;
  }
}

@media screen and (max-width: 1110px) {
  .button {
    margin-right: 10px;
    font-size: 12px;
    height: 36px;
    width: 150px;
  }
}

.dropdrop::-webkit-scrollbar {
  display: none;
}

.dropdrop {
  display: inline-block;
  overflow: auto;
  position: absolute;
  background: white;
  width: 227px;
  box-shadow: rgb(40 41 61 / 14%) -1px 4px 10px;
  z-index: 3;
}

input[name="search-box"] {
  display: block;
  max-width: 500px;
  width: 95%;
  padding: 0.5rem 1rem;
  padding-left: 25px;
}

.black {
  position: relative;
}

.black input {
  border: none;
  box-shadow: rgb(40 41 61 / 14%) -1px 4px 10px;
}

.search-item {
  padding: 5px;
  max-width: 500px;
  width: 100%;
  font-weight: 600;
  color: #82868c;
  font-size: 1rem;
  border-bottom: 1px solid #e8e8e8;
}

.search-item:last-child {
  border-bottom: none;
}

thead tr th{
  color: black;
}

.search-item:hover {
  background: #82868c;
  color: white;
}

.search-item hr {
  color: lightgray;
  border-top: none;
  margin: -1.3rem 0 0.5rem 0;
}

@media screen and (max-width: 763px) {
  .con {
    flex-wrap: wrap;
  }

  .active-inactive-con {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .button {
    height: auto;
  }
}

.is-active {
  background-color: #dedede;
}
</style>
